<template>
    <div class="page-qa-test">
        <div class="page-body">
            <div class="header">
                <img :src="`${ ossHost }img/icon-tl-box.jpg`" alt="">
                <div class="device-num">{{ uniqueId }}</div>
                <div class="device-type">{{ isFs ? '风送投料机控制盒' : '二代投料机控制盒' }}</div>
            </div>
            <div class="content" v-if="qaType != 2">
                <div class="content-title">一.功能质检：</div>
                <div class="content-item">
                    <div class="content-item-label">1.设备正常联网</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loading && !info.online" :color="themeColor" />
                        <span class="disable" v-if="!loaded">未检测</span>
                        <Icon name="close" v-if="!info.online && !loading" />
                        <Icon name="passed" v-if="info.online" />
                        <Button :color="themeColor" v-if="!info.online && !loading" @click="loadDeviceInfo('online')" size="small">重试</Button>
                    </div>
                </div>
                <div class="content-item" v-if="!isFs">
                    <div class="content-item-label">2.称重读数正常</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="startCheck && !info.weighReadStatus" :color="themeColor" />
                        <span class="disable" v-if="!startCheck">未检测</span>
                        <Icon name="close" v-if="info.weighReadStatus == 2 && startCheck"/>
                        <Icon name="passed" v-if="info.weighReadStatus == 1 && startCheck"/>
                        <span class="error" v-if="info.weighReadStatus == 2 && startCheck">未读取到重量</span>
                    </div>
                </div>
                <div class="content-item" v-else>
                    <div class="content-item-label">2.气压读数正常</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loading && !info.pressureReadStatus" :color="themeColor" />
                        <span class="disable" v-if="!info.pressureReadStatus && !loading">未检测</span>
                        <Icon name="close" v-if="info.pressureReadStatus == 2 && !loading"/>
                        <Icon name="passed" v-if="info.pressureReadStatus == 1 && !loading"/>
                        <span class="error" v-if="info.pressureReadStatus == 2 && !loading">气压传感器读数异常</span>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">3.三个旋钮调整至中间档位</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="startCheck && !info.motoStatus" :color="themeColor" />
                        <span class="disable" v-if="!startCheck">未检测</span>
                        <Icon name="close" v-if="info.motoStatus == 2 && startCheck"/>
                        <Icon name="passed" v-if="info.motoStatus == 1 && startCheck"/>
                        <span class="error" v-if="info.motoStatus == 2 && startCheck">速度,投料,间隔未调至中间</span>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-item-label">4.红外对管已被导通</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="startCheck && !info.infraredStatus" :color="themeColor" />
                        <span class="disable" v-if="!startCheck">未检测</span>
                        <Icon name="close" v-if="info.infraredStatus == 2 && startCheck" />
                        <Icon name="passed" v-if="info.infraredStatus == 1 && startCheck" />
                        <span class="error" v-if="info.infraredStatus == 2 && startCheck">未被导通</span>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-item-label">5.扫码质检期间进行开关操作</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="startCheck && !info.openStatus" :color="themeColor" />
                        <span class="disable" v-if="!startCheck">未检测</span>
                        <Icon name="close" v-if="info.openStatus == 2 && startCheck"/>
                        <Icon name="passed" v-if="info.openStatus == 1 && startCheck"/>
                        <span class="error" v-if="info.openStatus == 2 && startCheck">未查询到开关操作</span>
                    </div>
                </div>
                <div class="content-item" @click="zdqCheck = !zdqCheck">
                    <div class="content-item-label">6.已检查并确认震动器正常工作</div>
                    <div class="content-item-value">
                        <span :class="{'check-item': true, 'on': zdqCheck }"></span>
                    </div>
                </div>
            </div>

            <div class="content" v-else>
                <div class="content-title">二.称重质检：</div>
                <div class="content-item">
                    <div class="content-item-label">1.称重套装质检</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="startCheck && !info.weighReadStatus" :color="themeColor" />
                        <span class="disable" v-if="!startCheck">{{!info.online ? info.msg : '未检测'}}</span>
                        <Icon name="close" v-if="info.weighReadStatus == 2 && startCheck"/>
                        <Icon name="passed" v-if="info.weighReadStatus == 1 && startCheck"/>
                        <span class="error" v-if="info.weighReadStatus == 2 && startCheck">未读取到重量</span>
                    </div>
                </div>
            </div>
            <div class="footer" v-if="qaType != 2">
                <div class="btn" v-if="info.online && zdqCheck && !loading" @click="onclickStartBtn">{{startCheck ? '重新质检' : '开始检测'}}</div>
                <div class="btn disabled" v-else>{{ startCheck && loading ? '检测中...' : '开始检测'}}</div>
            </div>
            <div class="footer" v-else>
                <div class="btn" v-if="info.online && info.weighReadStatus && !loading" @click="startCheckAction">{{startCheck ? '重新质检' : '开始检测'}}</div>
                <div class="btn disabled" v-else>{{ startCheck && loading ? '检测中...' : '开始检测'}}</div>
            </div>
        </div>

        <Popup v-model="isShowModal" round @close="isShowModal = false">
            <div class="modal-body">
                <div class="title">请确认已执行以下操作</div>
                <div class="tip-text">1.控制盒已经连接pcb板；</div>
                <div class="tip-text">2.面板的三个旋钮已调至中间档位；</div>
                <div class="tip-text">3.红外对管也被导通；</div>
                <div class="tip-text">4.近10分钟内有过开关操作；</div>

                <div class="modal-btn" @click="onclickStartBtn(1)">开始检测</div>
            </div>
        </Popup>

        <Popup v-model="isShowSuccModal" round>
            <div class="modal-body succ-modal">
                <Icon name="checked" />
                <div class="title">质检成功</div>
                <div class="tip-text">结果已自动上传并保存,即将退回首页</div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Dialog, Icon, Button, Loading, Popup } from "vant";

export default {
    name: 'TlQa',
    components: {
        Icon,
        Button,
        Loading,
        Popup
    },
    data() {
        const { ossHost } = this.$config.base;
        // qaType: 1控制盒质检，2称重质检
        // isFs: 1代表风送控制盒质检
        const { uid, isFs, qaType } = this.$route.query;
        return {
            ossHost,
            themeColor: '#209A56',
            uniqueId: uid,
            // 是否获取过设备数据
            loaded: false,
            loading: false,
            info: {},
            // 是否开始检测过
            startCheck: false,
            isShowModal: false,
            isShowSuccModal: false,
            isCheck: false,
            // 震动器是否正常工作
            zdqCheck: false,
            isFs: isFs == 1,
            qaType: Number(qaType) || 1
        }
    },
    created() {
        const vm = this
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')
        const fType = vm.$strTool.filterFactoryType(uuid)
        vm.name = name;
        if (fType != 'wangbo') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        vm.startCheckAction();
    },

    methods: {
        // 获取设备检测状态
        loadDeviceInfo() {
            const vm = this
            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}tl/kzh/qualityCheckStatus?deviceIds=${vm.$route.params.id}`,
            }).then(res => {
                vm.info = res.data[0] || {}
                vm.loaded = true;
                vm.time++
                vm.checkTime++
                if (!vm.startCheck) {
                    setTimeout(() => {
                        vm.loading = false;
                    }, 2000);
                }
                // 通过质检
                if (vm.info.status == 2 && vm.startCheck) {
                    clearInterval(vm.clearIntervalId)
                    vm.isShowSuccModal = true;
                    vm.loading = false;
                    setTimeout(() => {
                        vm.$router.back()
                    }, 3000);
                }
                if (vm.time >= 120 || !vm.info.online) {
                    clearInterval(vm.clearIntervalId)
                    !vm.info.online && (vm.startCheck = false);
                    console.log(vm.info)
                    vm.info.online && Dialog.alert({
                        title: '提示',
                        message: `质检超时，请确认各项操作后再重新开始质检`,
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        vm.loading = false
                    });
                }

                if (((vm.info.infraredStatus == 2 || vm.info.motoStatus == 2 || vm.info.openStatus == 2 || vm.info.weighReadStatus == 2) && vm.qaType != 2) ||
                (vm.info.weighReadStatus == 2 && vm.qaType == 2) ||
                ((vm.info.infraredStatus == 2 || vm.info.motoStatus == 2 || vm.info.openStatus == 2 || vm.info.pressureReadStatus == 2) && vm.isFs)) {
                    // vm.info.infraredStatus = vm.info.infraredStatus == 2 ? 0 : vm.info.infraredStatus
                    // vm.info.motoStatus = vm.info.motoStatus == 2 ? 0 : vm.info.motoStatus
                    // vm.info.openStatus = vm.info.openStatus == 2 ? 0 : vm.info.openStatus
                    // vm.info.weighReadStatus = vm.info.weighReadStatus == 2 ? 0 : vm.info.weighReadStatus
                    // vm.info.pressureReadStatus = vm.info.pressureReadStatus == 2 ? 0 : vm.info.pressureReadStatus
                    if (vm.checkTime >= 20) {
                        vm.loading = false;
                        clearInterval(vm.clearIntervalId);
                    }
                }
            }).catch(err => {
                console.log(err);
            })
        },

        // 标记当前设备为质检状态
        startCheckAction() {
            const vm = this
            vm.loading = true;
            vm.$http({
                type: "put",
                url: `${vm.$config.base.PROBE_BASE_URL}tl/kzh/qualityCheck`,
                data: {
                    deviceIds: [vm.$route.params.id],
                    operation: 3,
                    userName: vm.name,
                    password: vm.$config.base.password,
                    // 默认控制盒质检
                    type: vm.qaType
                }
            }).then(() => {
                vm.time = 0;
                vm.checkTime = 0;
                clearInterval(vm.clearIntervalId)
                vm.startCheck = true
                vm.clearIntervalId = setInterval(vm.loadDeviceInfo, 1000);
                vm.info = {}
            })
        },

        // 开始检测
        onclickStartBtn(type) {
            const vm = this
            const isShowTlModalTip = localStorage.getItem('isShowTlModalTip')

            // 首次会展示提示弹框
            if (!isShowTlModalTip && type != 1) {
                vm.isShowModal = true;
                return
            }

            // 点击提示弹框中的开始检测
            if (type == 1) {
                localStorage.setItem('isShowTlModalTip', 1)
                vm.isShowModal = false;
            }
            vm.startCheckAction();
        }
    }
}
</script>

<style scoped lang="less">
@import "@/less/base.less";

.page-qa-test {
    width: 100%;
    height: 100%;
    background: @blank;
    padding: 10px;
    .page-body {
        font-size: 0.14rem;
        color: @textColor12;
        padding-bottom: 100px;
        .header {
            position: relative;
            padding-top: 0.2rem;
            img {
                position: absolute;
                width: 1.8rem;
                height: 1.4rem;
                right: 0.1rem;
                top: 0.1rem;
            }
            .device-num {
                font-size: 0.3rem;
                font-weight: bold;
                color: @black;
                padding-bottom: 0.1rem;
            }
            .device-type {
                font-size: 0.2rem;
            }
        }
        .content {
            box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.05);
            border-radius: 5px;
            padding: 10px;
            background: @blank;
            position: relative;
            z-index: 1;
            margin-top: 0.3rem;
            font-size: 0.16rem;
            &-title {
                font-size: 0.18rem;
                font-weight: bold;
                padding: 10px 0;
            }
            &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 70px;
                &-value {
                    display: flex;
                    align-items: center;
                    width: 40%;
                    word-break: break-all;
                    text-align: right;
                    justify-content: flex-end;
                    .disable {
                        color: @textColor13;
                    }
                    .van-icon {
                        font-size: 0.26rem;
                        margin-right: 5px;
                    }
                    .van-loading {
                        margin-right: 5px;
                    }
                    .van-icon-close,
                    .error {
                        color: @textColor2;
                    }
                    .van-icon-passed {
                        color: @themeColor;
                    }
                    .van-button {

                    }
                    .check-item {
                        width: 0.24rem;
                        height: 0.24rem;
                        border-radius: 50%;
                        border: 1px solid @textColor1;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.on {
                            border-color: @themeColor;
                            background: @themeColor;
                            &:after {
                                display: block;
                                content: ' ';
                                position: absolute;
                                width: 10px;
                                height: 4px;
                                border-bottom: 2px solid @blank;
                                border-left: 2px solid @blank;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
                &-label {
                    width: 45%;
                    word-break: break-all;
                }
            }
        }
        .footer .btn{
            width: 60%;
            left: 20%;
            line-height: 50px;
            text-align: center;
            background: @themeColor;
            color: @blank;
            font-size: 0.2rem;
            position: fixed;
            bottom: 20px;
            border-radius: 30px;
            z-index: 10;
            &.disabled {
                background: @textColor13;
            }
        }
    }
    .modal-body {
        padding: 20px 10px;
        color: @textColor1;
        letter-spacing: 1px;
        width: 2.8rem;
        &.succ-modal {
            text-align: center;
            .van-icon {
                color: @themeColor;
                font-size: 1rem;
            }
        }

        .title {
            text-align: center;
            font-size: 0.2rem;
            font-weight: bold;
        }
        .tip-text {
            margin-top: 10px;
            font-size: 0.18rem;
        }
        .modal-btn {
            width: 60%;
            margin: 30px auto 0;
            line-height: 50px;
            border-radius: 25px;
            background: @themeColor;
            text-align: center;
            color: @blank;
            font-size: 0.2rem;

        }
    }
}
</style>
